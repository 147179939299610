body {
  margin: 0;
  font-family: "Raleway", 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #12071f;
}

h1 {
  background: linear-gradient(30deg, #c850c0, #ffcc70);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#logoImage {
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 600px) {
  #logoImage {
    width: 40px;
    height: 40px;
  }
}

html, body, #root {
  overflow-x: hidden;
}
html {
  height: -webkit-fill-available; /* https://twitter.com/bfgeek/status/1262465912025698304 */
}
body {
  position: relative;
}